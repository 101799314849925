*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  background-color: transparent;
  color: #fff;
  height: 100%;
}

html {
  background-color: black;
}

a {
  color: red
}
a:visited {
  color: inherit;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

@font-face {
  font-family: 'Swiss';
  src: url('../fonts/Swiss.woff') format('woff');
  font-display: 'block';
}

@font-face {
  font-family: 'Clarendon Bold';
  src: url('../fonts/Clarendon-Bold.woff') format('woff');
  font-display: 'block';
}

@font-face {
  font-family: 'Clarendon';
  src: url('../fonts/Clarendon.woff') format('woff');
  font-display: 'block';
}

@font-face {
  font-family: 'Chronicle';
  src: url('../fonts/Chronicle.woff') format('woff');
  font-display: 'block';
}
